import { Link, Route, Routes, useLocation } from "solid-app-router";
import { Component, createEffect, lazy, Show } from "solid-js";

import Logo from "./assets/sciencepresence.svg";
import { logOut } from "./events";
import { api, setWasSignedIn, wasSignedIn } from "./helpers";
// import EditorTestPage from "./pages/EditorTestPage";
import { useStore } from "./state";

const StatsPage = lazy(() => import("./pages/ThingsThatHappenedPage"));
const LegalPage = lazy(() => import("./pages/LegalPage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const LogInPage = lazy(() => import("./pages/LogInPage"));
const SignUpPage = lazy(() => import("./pages/SignUpPage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const SubmitPage = lazy(() => import("./pages/SubmitPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
const HomePageSignedIn = lazy(() => import("./pages/HomePageSignedIn"));
const ListPage = lazy(() => import("./pages/ListPage"));
const ListsPage = lazy(() => import("./pages/ListsPage"));
const ArticlePage = lazy(() => import("./pages/ArticlePage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
const AdminPage = lazy(() => import("./pages/AdminPage"));

const Nav = () => {
  const { userId } = useStore();

  // const userId = () => null;

  return (
    <div class="mx-auto w-full max-w-6xl flex justify-between py-1">
      <Link href="/" class="mx-2 shrink mt-1">
        <img src={Logo} alt="Science Presence logo" class="w-full" />
      </Link>
      <nav class="flex justify-between md:text-xl">
        <Show
          when={userId() !== null}
          fallback={
            <>
              <Link
                class="mx-2 font-medium text-gray-500 hover:text-gray-900"
                href="/about"
              >
                about
              </Link>
              <Link
                class="mx-2 font-medium text-gray-500 hover:text-gray-900 whitespace-nowrap"
                href="/log-in"
              >
                log in
              </Link>
            </>
          }
        >
          <Link
            class="mx-2 font-medium text-gray-500 hover:text-gray-900"
            href="/submit"
          >
            submit
          </Link>
        </Show>

        <Show
          when={userId() !== null}
          fallback={
            <Link
              class="mx-2 font-medium text-gray-500 hover:text-gray-900 whitespace-nowrap"
              href="/sign-up"
            >
              sign up
            </Link>
          }
        >
          <Link
            class="mx-2 font-medium text-gray-500 hover:text-gray-900"
            href={`/profile`}
          >
            profile
          </Link>
        </Show>
      </nav>
    </div>
  );
};

const Footer: Component = () => {
  const { setUserID, userId } = useStore();

  return (
    <footer class="py-10 px-4 mt-10 w-full text-white bg-black">
      <div class="mx-auto max-w-6xl">
        <nav class="flex flex-row gap-4 whitespace-nowrap flex-wrap">
          <Link href="/about">About</Link>
          <Link href="/sign-up">Sign up</Link>
          <Link href="/log-in">Log in</Link>
          <Link href="/l">Lists</Link>
          <Show when={userId() !== null}>
            <button
              class=""
              onClick={async () => {
                const [_, err] = await api("/log-out", {});

                if (err) throw new Error("error logging out " + err);

                setUserID(null);
                setWasSignedIn(false);
                await logOut();

                window.location.assign("/");
              }}
            >
              Log out
            </button>
          </Show>
          <Link href="/legal">Privacy Policy</Link>
        </nav>
        <div class="mt-3 text-gray-400">
          <Link href="/stats">© 2022 Science Presence</Link>
        </div>
      </div>
    </footer>
  );
};

const App: Component = () => {
  const { userId } = useStore();
  const location = useLocation();

  createEffect(() => {
    const path = location.pathname + location.search + location.hash;
    (window as any).goatcounter?.count?.({
      path,
    });
  });

  return (
    <>
      <Nav />
      <main class="mx-auto max-w-4xl w-full pt-2 px-4 h-auto grow">
        <Routes>
          <Route
            path="/"
            element={
              <Show
                when={wasSignedIn() || userId()}
                fallback={
                  // <HomePage />
                  <HomePage />
                }
              >
                {/* <HomePage /> */}
                <HomePageSignedIn />
              </Show>
            }
          />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/a/:id" element={<ArticlePage />} />
          <Route path="/l/:id" element={<ListPage />} />
          <Route path="/l" element={<ListsPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/submit" element={<SubmitPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/log-in" element={<LogInPage />} />
          <Route path="/stats" element={<StatsPage />} />
          <Route path="/legal" element={<LegalPage />} />
          <Route path="/admin" element={<AdminPage />} />
          {/* <Route path="/editor" element={<EditorTestPage />} /> */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
};
export default App;
