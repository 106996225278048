import { api } from "./helpers";

export async function event(
  name: "Account Created",
  properties: { account_id: string; username: string }
): Promise<void>;
export async function event(
  name: "Account Logged In",
  properties: { account_id: string; username: string }
): Promise<void>;
export async function event(
  name: "List Entry Created",
  properties: {
    account_id: string;
    list_id: string;
    list_title: string;
    article_id: string;
  }
): Promise<void>;
export async function event(
  name: "List Entry Deleted",
  properties: {
    account_id: string;
    list_id: string;
    list_title: string;
    article_id: string;
  }
): Promise<void>;
export async function event(
  name: "List Created",
  properties: {
    account_id: string;
    list_id: string;
    list_title: string;
    list_description: string;
  }
): Promise<void>;
export async function event(
  name: "Article Submission Created",
  properties: {
    account_id: string;
    article_id: string;
    article_doi: string;
    article_title: string;
    article_url: string;
  }
): Promise<void>;
export async function event(name: string, properties: Object) {
}

export const identify = async (
  userId: string,
  set?: Record<string, any>,
  setOnce?: Record<string, any>
) => { };

export const logInStatusThenIdentify = async () => {
  const [details] = await api("/log-in-status");
  if (details) {
    const { account_id, username } = details;
    if (account_id && username) {
      await identify(account_id, { username: username });
      return { account_id, username };
    }
  }
};

export const logOut = () => { };
