import { createStore } from "solid-js/store";
import { inDevEnv } from "./config";
import { api, List, setWasSignedIn, wasSignedIn } from "./helpers";

type AppState = {
  signed_in_optimistic: boolean;
  user_id: string | null;
  lists_normalised: Record<number, List & { articles?: string[] }>;
};

const [state, setState] = createStore<AppState>({
  signed_in_optimistic: wasSignedIn(),
  user_id: null,
  lists_normalised: {},
});

const squashDuplicates = <T extends (...args: any) => void>(fn: T) => {
  let lastCall: number | null = null;

  return ((...args: any) => {
    const timeNow = new Date().getTime();
    if (lastCall !== null && timeNow - lastCall < 500) return;

    lastCall = timeNow;
    fn(...args);
  }) as T;
};

const refreshMyLists = async () => {
  const [lists, err] = await api("/my-lists");

  if (err) return;

  setState("lists_normalised", (existingLists) => ({
    ...existingLists,
    ...Object.fromEntries(lists.map((l) => [l.id, l])),
  }));
};
refreshMyLists.deduped = squashDuplicates(refreshMyLists);

const init = async () => {
  const [status, err] = await api("/log-in-status");

  if (err) throw new Error("error getting log in status " + err.msg);

  setWasSignedIn(!!status.account_id);

  setState("user_id", status.account_id);
};

const publicState = {
  setUserID: (user_id: AppState["user_id"]) => setState("user_id", user_id),

  get myLists() {
    refreshMyLists.deduped();
    const observer = () =>
      Object.values(state.lists_normalised).filter(
        (l) => l.account_id === state.user_id
      );
    observer.refresh = refreshMyLists;
    return observer;
  },

  userId: () => state.user_id,

  _raw: state,

  init,
} as const;

if (inDevEnv)
  ((globalThis as any).m = publicState),
    ((globalThis as any).s = state),
    ((globalThis as any).ss = setState);

export const useStore = () => publicState;
