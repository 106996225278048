import { parse, stringify, version } from "uuid";
import { fromUint8Array, toUint8Array } from "js-base64";

export function uuidToBase64(uuid: string): string {
  const v = version(uuid); // calling version implicitly validates
  if (v !== 4)
    throw new Error(`expected uuid version 4, got version ${v} from "${uuid}"`);
  return fromUint8Array(parse(uuid) as any, true);
}

export function uuidFromBase64(encoded: string): string {
  return stringify(toUint8Array(encoded));
}
