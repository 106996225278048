import { uuidFromBase64, uuidToBase64 } from "./base64";
import { apiUrl, canonicalBaseUrl, inDevEnv } from "./config";

// Meta Elements
const metaNoindexElement: HTMLMetaElement =
  document.querySelector("meta[name=robots]") || document.createElement("meta");
metaNoindexElement.name = "robots";
metaNoindexElement.content = "noindex";

const metaDescriptionElement: HTMLMetaElement =
  document.querySelector("meta[name=description]") ||
  document.createElement("meta");
metaDescriptionElement.name = "description";
document.head.append(metaDescriptionElement);

const metaCanonicalElement: HTMLLinkElement =
  document.querySelector("link[rel=canonical]") ||
  document.createElement("link");
metaCanonicalElement.rel = "canonical";
document.head.append(metaCanonicalElement);

export const setPageMeta = ({
  title,
  description = "",
  canonical,
  noindex,
}: {
  title: string;
  description?: string;
  canonical: string;
  noindex?: true;
}) => {
  document.title = title;

  metaDescriptionElement.content = description;

  if (canonical !== undefined) {
    metaCanonicalElement.href = canonicalBaseUrl + canonical;
  }

  if (noindex === true && !document.head.contains(metaNoindexElement)) {
    document.head.appendChild(metaNoindexElement);
  } else if (
    noindex === undefined &&
    document.head.contains(metaNoindexElement)
  ) {
    metaNoindexElement.remove();
  }
};

export type Article = {
  id: string;
  title: string;
  url: string;
  doi: string;
};

export type List = {
  id: string;
  title: string;
  account_id: string;
  description: string;
  // articles: number[];
};

type UserPageResult =
  | [{ id: string } | null, (List & { items: number })[]]
  | null;

type ArticleResult = Article & { submitter: number | null };

type HomePageResults = (Article & {
  saved: number | null;
  account_id: string | null;
  account_username: string | null;
  created: string | null;
})[];

type ApiResponse<T> = Promise<
  | [T, null]
  // error is in an object to prevent user errors around falsy strings
  // mixed on whether message should be string or string | undefined...
  | [null, { msg: string }]
>;

export async function api(url: `/profile`): ApiResponse<UserPageResult>;
export async function api(url: `/home`): ApiResponse<HomePageResults>;
export async function api(
  url: `/log-in-status`
): ApiResponse<{ account_id: string | null; username: string | null }>;
export async function api(url: `/lists`): ApiResponse<List[]>;
export async function api(
  url: `/my-lists`
): ApiResponse<(List & { articles: number[] })[]>;
export async function api(
  url: `/list/${string}`
): ApiResponse<
  [List, (Article & { created: string })[], { id: string; username: string }]
>;
export async function api(
  url: `/article/${string}`
): ApiResponse<[ArticleResult, [string, string]] | null>;
export async function api(
  url: `/admin/users_list`
): ApiResponse<
  [{ id: string; email: string; username: string; created: string }] | null
>;
export async function api(
  url: `/sign-up`,
  body: { email: string; username: string; password: string }
): ApiResponse<{ user_id: string }>;
export async function api(
  url: `/log-in`,
  body: { email: string; password: string }
): ApiResponse<{ user_id: string }>;
export async function api(url: `/log-out`, body: {}): ApiResponse<{}>;
export async function api(
  url: `/lists/add`,
  body: { list: string; article: string }
): ApiResponse<{}>;
export async function api(
  url: `/lists/remove`,
  body: { list: string; article: string }
): ApiResponse<{}>;
export async function api(
  url: `/star/${string}`,
  body: {}
): ApiResponse<string>;
export async function api(
  url: `/search`,
  body: { title: string }
): ApiResponse<CrossrefQueryItem[]>;
export async function api(
  url: `/submit`,
  body: { doi: string }
): ApiResponse<Article>;
export async function api(
  url: "/list/create",
  body: { title: string; description: string }
): ApiResponse<List>;
export async function api(
  url: "/list/update",
  body: { id: string; title: string | null; description: string | null }
): ApiResponse<{}>;
export async function api(
  url: "/stats",
  body: { days: number }
): ApiResponse<
  {
    date: string;
    articles: number;
    lists: number;
    list_entries: number;
    accounts: number;
  }[]
>;
export async function api(
  url: "/resource_info",
  body: { resources: string[] }
): ApiResponse<[{ type: "Exists"; data: ArticleResult } | { type: "Pending" }]>;
export async function api(url: string, body?: object) {
  const method = body === undefined ? "GET" : "POST";
  try {
    const res = await fetch(apiUrl + url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: body && JSON.stringify(body),
    });

    if (res.ok) return [await res.json(), null];

    switch (res.status) {
      // expected errors
      case 400:
        return [null, { msg: await res.text() }];

      // unexpected errors
      case 404:
        console.error(`received 404 attempting to ${method} "${apiUrl + url}"`);
      // intentional fallthrough
      default:
        if (inDevEnv) console.debug(res);
        return [null, { msg: `Error processing request: ${res.status}` }];
    }
  } catch (err) {
    if (inDevEnv)
      console.error(`error attempting to ${method} "${apiUrl + url}"`, err);
    return [null, { msg: "Error" }];
  }
}

export type CrossrefQueryItem = {
  doi: string;
  url: string;
  title: string[];
  container_title: string[];
  references_count: number;
  subject: string[] | null;
  short_container_title: string[] | null;
  published_print: { date_parts: number[][] } | null;
};

export const idFromSegment = (segment: string) => {
  if (segment.length < 22) return null;
  return uuidFromBase64(segment.slice(segment.length - 22, segment.length));
  // const separatorIndex = segment.lastIndexOf("-");
  // const id = parseInt(
  //   separatorIndex === -1 ? segment : segment.slice(separatorIndex + 1)
  // );

  // return !Number.isSafeInteger(id) || id < 0 ? null : id;
};

export const idUrlSegment = (title: string, id: string) => {
  const niceTitle = title.replaceAll(/[^a-zA-Z0-9]+/g, "-").slice(0, 40);
  const separator = niceTitle.endsWith("-") ? "" : "-";
  return niceTitle + separator + uuidToBase64(id);
};

export const urlHost = (url: string) => {
  try {
    return new URL(url).host;
  } catch (e) {
    return url;
  }
};

export const wasSignedIn = () => localStorage?.getItem("wasSignedIn") === "y";
export const setWasSignedIn = (signedIn: boolean) =>
  localStorage?.setItem("wasSignedIn", signedIn ? "y" : "n");

export const createDebounce = <T extends (...args: any) => void>(f: T): T => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return ((...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => f(...args), new Date().getTime() + 2000);
  }) as T;
};
